import React from 'react'
import Navbar from './Navbar'

const Ownership = () => {
  return (
    <div>
    <Navbar />
    <section id="services">
    <div class="main-container-services">
    <div class="container-service">             
      <div class="service-text">
        <h3>BP Ownership - Who we are</h3>
          <p>Animus Systems SL is owned 50% by Marcin Jacek Seth Choscilowicz and 50% by Jowita Maria Choscilowicz and we represent BP Boid as the provider of the infrastructure.</p>
          <p>You can check more on LinkedIn.</p>
          <p><a href="https://www.linkedin.com/in/mchosc/" target="_blank">Seth</a>, <a href="https://www.linkedin.com/in/jchoscilowicz/" target="_blank">Jowita</a></p>
      </div>
    </div>
    </div>
    </section>
    </div>
  )
}

export default Ownership