import React from 'react'
import "./Home.scss"

const Footer = () => {
  return (
    <footer id="footer">
    <div class="container-footer">
        <a href="#"><img src={process.env.PUBLIC_URL + 'logo-footer.png'} alt="logo"></img></a>
    </div>
    <div class="footer-links">
        <a href="#services">services</a>
        <a href="#">blockchain</a>
        <a href="#">analytics</a>
        <a href="#">infra</a>
        <a href="#contact">contact</a>
    </div>
    <div class="container-footer2">
        <div class="item-footer">
            <h3>Animus Systems SL</h3>
            <p>CIF: 809801200</p>  
        </div>
        <div class="item-footer">
            <h3>Email</h3>
            <p>contact@animusystems.com <br></br> Complaints: complaints@animusystems.com</p>
        </div>
        <div class="item-footer">
            <h3>Address</h3>
            <p>Santa Cruz de Tenerife, Canary Islands, Spain</p>
        </div>
    </div>
</footer>
  )
}

export default Footer