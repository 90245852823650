import React from 'react'
import Navbar from './Navbar'

const CodeOfConduct = () => {
  return (
    <div>
    <Navbar />
    <section id="services">
    <div class="main-container-services">
    <div class="container-service">
      <div>
        <h3>CODE OF CONDUCT - OUR COMMITMENT</h3>
        <p></p>
          <h3>Governance</h3>
          <p>We solemnly declare to uphold and abide by democratically agreed upon governing rules and procedures described in the Governance Documents and to uphold any non-disclousure agreements with other blockchain networks we support whether public or private. We will not comply with any requests that are forbidden by governance rules, procedures, or NDAs.</p>
          <h3>Arbitration Decisions</h3>
          <p>We will abide by, follow, and enforce all arbitration decisions that are enacted, without delay in accordance with the Blockchain Network Arbitration Rules and Procedures along with arbitration standards and procedures established by all networks we support. We will abstain from publishing opinions on active arbitration.</p>  
          <h3>High Network Performance</h3>
          <p>We will operate effectively and efficiently in accordance with the Blockchain Network Operating Agreement along with any other supported networks' requirements. We will meet and exceed Block Producer Minimum Requirements. We will always place the highest priority on investing back into the required network infrastructure and people to enable all of the networks we support to perform at the highest level and scale effectively.</p>
          <h3>Honesty, Integrity, and Ethics</h3>
          <p>As a block producer, we will always act with honesty, integrity and apply the highest ethical standards in all aspects of our conduct. This includes abiding by all aspects of the governance and arbitration, compliance, and community engagement.</p>
          <h3>Political and Operational Independence</h3>
          <p>BP Boid will maintain full political and operational independence at all times. We will not directly manage or operate more than one block producing node within any singular EOSIO blockchain.</p>
          <h3>Commitment to Token-holders</h3>
          <p>We commit to maintaining an ongoing dialogue with the community wherever conversations are being held, regardless of the size of the stake or the number of tokens held. We commit to listening, understanding, and achieving consensus with the token-holder community. We encourage constructive dialogue and criticism as well as collaboration and support within the entire EOSIO community.</p>
          <h3>Cooperation</h3>
          <p>We welcome all opportunities to cooperate with other global block producers, regardless of geographic location, that do not compromise our financial, political, or operational independence. We believe that cooperation is healthy for the whole ecosystem and allows for the completion of tasks larger than ourselves, including, but not limited to coordinating software upgrades, properly diversifying network infrastructure across wide geopolitical regions, and always listening to the community’s voice. We believe in cooperation between EOSIO chains.</p>
          <h3>Financial Independence</h3>
          <p>BP Boid is 100% self-funded and owned. It's operated by Animus Systems SL. We will maintain full financial independence at all times. This includes no operational control by any outside investors and no ownership stake held by any individual or entity that holds vested interests in other network block producers, other supported networks, or the publishers of the EOSIO software, block.one.</p>
          <h3>Transparency</h3>
          <p>BP Boid will provide transparency into our operating team, ownership and financials. We will always fully disclose all blockchain networks we support and participate in, along with any dapp partnerships and collaboration. Our team will be transparent to each community and will always seek to exceed ongoing community transparency requirements as they evolve.</p>
      </div>
    </div>
    </div>
    </section>
    </div>
  )
}

export default CodeOfConduct