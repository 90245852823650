export const InfraData = [
    {
        chain: "Telos Testnet",
        service: "API (v1, SSL)",
        address: "https://telos.testnet.boid.animus.is/",
        info:"Public API",
    },
    {
        chain: "Telos Mainnet",
        service: "P2P",
        address: "telos.p2p.boid.animus.is:5252",
        info:"Public P2P",
    },
    {
        chain: "Telos Mainnet",
        service: "API (v1, SSL)",
        address: "https://telos.api.boid.animus.is",
        info:"Public API",
    },
    {
        chain: "Telos Testnet",
        service: "P2P",
        address: "telos.testnet.boid.animus.is:3535",
        info:"Public P2P",
    },
    {
        chain: "Telos Mainnet",
        service: "gRPC",
        address: "firehose.boid.animus.is:9000",
        info:"Community edition with rate limiter",
    },
    {
        chain: "Telos Mainnet & Testnet",
        service: "oracle & RNG",
        address: "bp.boid",
        info:"Part of Boid BP Service",
    },
    {
        chain: "Telos Mainnet",
        service: "REX Pay update",
        address: "exrsrv.tf - pay",
        info:"Part of Boid BP Service",
    },
    {
        chain: "Telos & EOS Mainnet",
        service: "IBC",
        address: "",
        info:"Part of T-Swaps bridge",
    },
    {
        chain: "Europechain Mainnet",
        service: "API (v1, SSL)",
        address: "https://europechain.api.animus.is",
        info:"Public API",
    },
    {
        chain: "Europechain Mainnet",
        service: "P2P",
        address: "bp-europechain.animusystems.com:5454",
        info:"Public P2P",
    },
    {
        chain: "EOS Mainnet",
        service: "API (v1, SSL)",
        address: "https://eos.api.boid.animus.is",
        info:"Public API",
    },

]
