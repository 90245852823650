import React from 'react'
import "./Infra.scss"
import Navbar from './Navbar'
import { InfraData } from "./InfraData"
import Footer from "./Footer"

const Infra = () => {
  return (
    <div>
    <Navbar />

<div className="infra">
<h1 className="infra-h1">Services running now on <span>EOSIO</span> chains</h1>
    <div className="infra-container">
      <div className="infra-titles">
        <p className="infra-titles-p">Chain</p>
        <p className="infra-titles-p">Service</p>
        <p className="infra-titles-p">Address</p>
        <p className="infra-titles-p">Info</p>
      </div>
        {InfraData.map ((item, index) =>
         <div className="infra-item" id={index}>
              <p className="infra-item-p bold">{item.chain}</p>
              <p className="infra-item-p">{item.service}</p>
              <p className="infra-item-p">{item.address}</p>
              <p className="infra-item-p">{item.info}</p>
        </div>
          )}
    </div>
</div>
<Footer />
    </div>
  )
}

export default Infra