import React from 'react'
import "./Analytics.scss"
import Navbar from './Navbar'
import Footer from "./Footer"

const Analytics = () => {
  return (
    <div>
    <Navbar />
    
    <section id="tailored">
        <h2><span>Tailored </span>metrics</h2>
        <div class="tailored-container">
            <div class="tailored-text">
                <ul>
                    <li>
                        <p>Visualizing platform usage metrics.</p>
                    </li>
                    <li>
                        <p>Visualizing protocol usage information.</p>
                    </li>
                    <li>
                        <p>Visualizing telegram tipping usage,</p><p>governance voting and platform usage.</p>
                    </li>
                    <li>
                        <p>REST API based dashboard.</p>
                    </li>
                    <li>
                        <p>Visualizing swap volume and market information.</p>
                    </li>
                    <li>
                        <p>Visualizing flash loans / liquidity pools metrics.</p>
                    </li>
                    <li>
                        <p>Resource usage visualization.</p><p>Chain infrastructure node response monitoring.</p>
                    </li>
                    <li>
                        <p><a href="https://stats.boid.com/" target="_blank">Official Animus live stats page.</a></p>
                    </li>
                </ul>
            </div>
            <div class="tailored-img1">
                <div class="border"></div>
                <img src={process.env.PUBLIC_URL + 'tailored-img1.png'} alt="tailored-img1"></img>
            </div>
            <div class="tailored-img2">
                <div class="border"></div>
                <img src={process.env.PUBLIC_URL + 'tailored-img2.png'} alt="tailored-img2"></img>
            </div>
        </div>
        <div class="circulo"></div>
    </section>
    <Footer />
    </div>
  )
}

export default Analytics