import React, { useState } from 'react'
import "./Home.scss"

const Navbar = () => {

    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
      setActive(!isActive);  };

  return (
    <header>
        <div class="logo">
            <a href="/#">
                    <img src={process.env.PUBLIC_URL + 'logo_desktop.png'}></img>
            </a>
                
        </div>
        <nav className={isActive ? null : "open-nav" }>
            <svg onClick={handleToggle} class="close" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.1871 0L9 7.18714L1.81286 0L0 1.81286L7.18714 9L0 16.1871L1.81286 18L9 10.8129L16.1871 18L18 16.1871L10.8129 9L18 1.81286L16.1871 0Z" fill="black"/>
            </svg>
            
            <ul>
                <li><a onClick={handleToggle} class="link1" href="/#services">services</a></li>
                <li><a onClick={handleToggle} class="link2" href="/blockchain">blockchain</a></li>
                <li><a onClick={handleToggle} class="link3" href="/analytics">analytics</a></li>
                <li><a onClick={handleToggle} class="link4" href="/infra">infra</a></li>
                <li><a onClick={handleToggle} class="link5" href="/#contact">contact</a></li>    
            </ul>
        </nav>
        <div class="social-links">
            <a target="_blank" href="https://www.linkedin.com/company/34903547">
                    <svg class="icon linkedin" width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 0.47998C5.63758 0.47998 0.47998 5.63758 0.47998 12C0.47998 18.3624 5.63758 23.52 12 23.52C18.3624 23.52 23.52 18.3624 23.52 12C23.52 5.63758 18.3624 0.47998 12 0.47998ZM9.17998 16.7748H6.84718V9.26758H9.17998V16.7748ZM7.99918 8.34598C7.26238 8.34598 6.78598 7.82398 6.78598 7.17838C6.78598 6.51958 7.27678 6.01318 8.02918 6.01318C8.78158 6.01318 9.24238 6.51958 9.25678 7.17838C9.25678 7.82398 8.78158 8.34598 7.99918 8.34598ZM17.7 16.7748H15.3672V12.6144C15.3672 11.646 15.0288 10.9884 14.1852 10.9884C13.5408 10.9884 13.158 11.4336 12.9888 11.862C12.9264 12.0144 12.9108 12.2304 12.9108 12.4452V16.7736H10.5768V11.6616C10.5768 10.7244 10.5468 9.94078 10.5156 9.26638H12.5424L12.6492 10.3092H12.696C13.0032 9.81958 13.7556 9.09718 15.0144 9.09718C16.5492 9.09718 17.7 10.1256 17.7 12.336V16.7748Z" fill="#294892"/>
                    </svg>
            </a>
            <a target="_blank" href="https://facebook.com/animusystems">
                    <svg class="icon facebook" width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12.067C0 18.033 4.333 22.994 10 24V15.333H7V12H10V9.333C10 6.333 11.933 4.667 14.667 4.667C15.533 4.667 16.467 4.8 17.333 4.933V8H15.8C14.333 8 14 8.733 14 9.667V12H17.2L16.667 15.333H14V24C19.667 22.994 24 18.034 24 12.067C24 5.43 18.6 0 12 0C5.4 0 0 5.43 0 12.067Z" fill="#294892"/>
                    </svg>
            </a>
            <a target="_blank" href="https://t.me/I_Seth">
                    <svg class="icon telegram" width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24 12C24 18.627 18.627 24 12 24C5.373 24 0 18.627 0 12C0 5.373 5.373 0 12 0C18.627 0 24 5.373 24 12ZM12.43 8.859C11.263 9.344 8.93 10.349 5.432 11.873C4.864 12.099 4.566 12.32 4.539 12.536C4.493 12.902 4.951 13.046 5.573 13.241C5.658 13.268 5.746 13.295 5.836 13.325C6.449 13.524 7.273 13.757 7.701 13.766C8.09 13.774 8.524 13.614 9.003 13.286C12.271 11.079 13.958 9.964 14.064 9.94C14.139 9.923 14.243 9.901 14.313 9.964C14.383 10.026 14.376 10.144 14.369 10.176C14.323 10.369 12.529 12.038 11.599 12.902C11.309 13.171 11.104 13.362 11.062 13.406C10.968 13.503 10.872 13.596 10.78 13.685C10.21 14.233 9.784 14.645 10.804 15.317C11.294 15.64 11.686 15.907 12.077 16.173C12.504 16.464 12.93 16.754 13.482 17.116C13.622 17.208 13.756 17.303 13.887 17.396C14.384 17.751 14.831 18.069 15.383 18.019C15.703 17.989 16.035 17.688 16.203 16.789C16.6 14.663 17.382 10.059 17.563 8.161C17.574 8.00341 17.5673 7.84509 17.543 7.689C17.5285 7.56293 17.4671 7.44693 17.371 7.364C17.228 7.247 17.006 7.222 16.906 7.224C16.455 7.232 15.763 7.473 12.43 8.859Z" fill="#294892"/>
                    </svg> 
            </a>              
        </div>

        <div class="support">
            <a href="https://get.teamviewer.com/6pgdexv">Quick Support</a>
        </div>
        
        <svg onClick={handleToggle} class="menu" width="29" height="24" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0H25V3.16667H0V0ZM6.25 7.91667H25V11.0833H6.25V7.91667ZM14.0625 15.8333H25V19H14.0625V15.8333Z" fill="#294892"/>
            </svg>
            
            
    </header>
  )
}

export default Navbar