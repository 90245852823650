import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Home from "./components/Home"
import Blockchain from "./components/Blockchain"
import Analytics from "./components/Analytics"
import Infra from "./components/Infra"
import Ownership from './components/Ownership';
import CodeOfConduct from './components/CodeOfConduct';

function App() {
  return (
    <div>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/blockchain" element={<Blockchain />} />
      <Route path="/analytics" element={<Analytics />} />
      <Route path="/infra" element={<Infra />} />
      <Route path="/ownership" element={<Ownership />} />
      <Route path="/code-of-conduct" element={<CodeOfConduct />} />
    </Routes>
  </BrowserRouter>
    </div>
  );
}

export default App;
