import React from 'react'
import "./Blockchain.scss"
import Navbar from './Navbar'
import Footer from './Footer'


const Blockchain = () => {
  return (
    <div>
      <Navbar />
    <section id="boid">
        <h2><span>Projects</span> we're working with</h2>
        <div class="boid-container">
            <div class="boid-text">
                <ul>
                    <li>
                        <h3>dApp</h3>
                        <p>Boid is an EOSIO decentralized application (dApp). At the heart of Boid is the desire to make the benefits of blockchain technology accessible to all people.</p>
                    </li>
                    <li>
                        <h3>Gateway</h3>
                        <p>Boid acts as a gateway, lowering the barrier to entry for non-technical users to directly participate in blockchains and distributed computing projects.</p>
                    </li>
                    <li>
                        <h3>Community</h3>
                        <p>People involved in Boid have a real impact on research in medicine, climatology, astronomy and machine learning.</p>
                    </li>
                    <li>
                        <h3>Economy</h3>
                        <p>Your Boid Power can be used to mine for various cryptos. Get paid out daily however you like.</p>
                    </li>
                </ul>
                <div class="main-btn">
                    <a target="_blank" href="https://www.boid.com/">learn more</a>
                    <svg class="arrow" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 7L0.5 13.0622L0.500001 0.937822L11 7Z" fill="#fff"/>
                    </svg>
                </div>       
            </div>
            <div class="boid-img">
                <img src={process.env.PUBLIC_URL + 'boid-img.png'} alt="boid-img"></img>
            </div>
        </div>
        <div class="circulo"></div>
    </section>
    <section id="blockbase">
        <div class="base-text">
            <h2>The power of <span>Blockchain</span> applied to databases</h2>
            <p>BlockBase uses blockchain technology to provide secure and distributed database storage services. It stores database operations on sidechains to the EOSIO chains, which are governed through smart contracts on those chains. To learn more about how BlockBase works, check our documentation on GitHub below.</p>
            <div class="main-btn">
                <a target="_blank" href="https://www.blockbase.network/">learn more</a>
                <svg class="arrow" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 7L0.5 13.0622L0.500001 0.937822L11 7Z" fill="#fff"/>
                </svg>
            </div>  
        </div>
        <div class="base-img">
            <img src={process.env.PUBLIC_URL + 'base-img.png'} alt="blockbase-img"></img>
        </div>
    </section>
    <section id="europechain">
        <div class="europe-text">
            <h2>The Enterprise <span>Blockchain</span> Europe Needs</h2>
            <p>Built from the ground up for enterprises in the EU. Europechain is a GDPR supporting enterprise blockchain platform managed and governed by experienced validators registered in the European Union.</p>
            <div class="main-btn">
                <a target="_blank" href="https://europechain.io/">learn more</a>
                <svg class="arrow" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 7L0.5 13.0622L0.500001 0.937822L11 7Z" fill="#fff"/>
                </svg>
            </div>  
        </div>
        <div class="europe-img">
            <img src={process.env.PUBLIC_URL + 'europechain-img.png'} alt="europechain-img"></img>
        </div>
    </section>
    <section id="gamification">
        <div class="game-text">
            <h2>Gamification <span>powered</span> on the blockchain</h2>
            <p>Powering third-party applications to create challenges and tasks that utilize gaming mechanics to motivate and promote user engagement. <br></br><br></br>
            Bountyblok.io's APIs allow your team to create challenges and tasks to further engage your users. Increase their activity within your web or mobile apps through minimal effort and integration. Create a challenge, define some rules, and reward your users.</p>
            <div class="main-btn">
                <a target="_blank" href="https://www.bountyblok.io/">learn more</a>
                <svg class="arrow" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 7L0.5 13.0622L0.500001 0.937822L11 7Z" fill="#fff"/>
                </svg>
            </div>  
        </div>
        <div class="game-img">
            <img src={process.env.PUBLIC_URL + 'game-img.png'} alt="game-img"></img>
        </div>
    </section>
    {/* <section id="instars">
        <div class="instars-text">
            <h2>Join us now, start <span>earning</span> today</h2>
            <p>Join Instars and become a part of the growing Instars Community with dedicated members across the globe. Start earning crypto, benefiting from your data, and receiving exclusive opportunities while being social.</p>
            <div class="main-btn">
                <a target="_blank" href="https://instars.com/">learn more</a>
                <svg class="arrow" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 7L0.5 13.0622L0.500001 0.937822L11 7Z" fill="#fff"/>
                </svg>
            </div>  
        </div>
        <div class="instars-img">
            <img src={process.env.PUBLIC_URL + 'instars-img.png'} alt="instars-img"></img>
        </div>
    </section> */}
    <section id="telos">
        <div class="telos-text">
            <h2>Home to the <span>Fastest</span> EVM</h2>
            <p>A blockchain reimagining what's possible. Telos is faster, cheaper, greener, and honest. Beyond function, we are leading the way for a better Web 3.</p>
            <div class="main-btn">
                <a target="_blank" href="https://www.telos.net/">learn more</a>
                <svg class="arrow" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 7L0.5 13.0622L0.500001 0.937822L11 7Z" fill="#fff"/>
                </svg>
            </div>  
        </div>
        <div class="telos-img">
            <img src={process.env.PUBLIC_URL + 'telos-img.png'} alt="telos-img"></img>
        </div>
    </section>
    <Footer />
    </div>
  )
}

export default Blockchain